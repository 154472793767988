var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "location-entry-carbon-dialog",
        "header-class": "header-class-modal-doc-package",
        title: _vm.FormMSG(122, "Location energy consumption"),
        size: "xl",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "cancel-title": _vm.FormMSG(121, "Cancel"),
        "ok-variant": "primary",
        "ok-disabled": _vm.loadingForSubmit,
        "cancel-disabled": _vm.loadingForSubmit,
        "cancel-variant": "custom-outline-gray",
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleOk.apply(null, arguments)
        },
        cancel: _vm.emitEventClose,
        hidden: _vm.emitEventClose,
      },
      scopedSlots: _vm._u([
        {
          key: "modal-ok",
          fn: function () {
            return [
              _c(
                "div",
                [
                  _vm.loadingForSubmit
                    ? _c("b-spinner", { attrs: { small: "" } })
                    : _vm._e(),
                  _vm._v(
                    "\n\t\t\t" + _vm._s(_vm.FormMSG(135, "Save")) + "\n\t\t"
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                [
                  _c(
                    "b-alert",
                    {
                      attrs: {
                        show: _vm.showError,
                        variant: "danger",
                        dismissible: "",
                      },
                      on: { dismissed: _vm.handleClickDismissed },
                    },
                    [_vm._v("\n\t\t\t\t\t" + _vm._s(_vm.error) + "\n\t\t\t\t")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "perfect-scrollbar",
            {
              ref: "containerLocationEntryCarbon",
              staticClass: "container-location-entry-carbon",
            },
            [
              _c(
                "div",
                { attrs: { id: "containerLocationEntryCarbon" } },
                _vm._l(_vm.carbonList, function (item, index) {
                  return _c("div", { key: index }, [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex justify-content-around",
                        attrs: { id: `item_${index}` },
                      },
                      [
                        _c("div", { staticStyle: { width: "92%" } }, [
                          _c(
                            "fieldset",
                            {
                              staticClass: "my-0 py-0 pb-0",
                              class: `${
                                _vm.$screen.width <= 576
                                  ? ""
                                  : "scheduler-border"
                              }`,
                              attrs: { tyle: "height: 100%" },
                            },
                            [
                              _vm.$screen.width > 576
                                ? _c(
                                    "legend",
                                    {
                                      staticClass:
                                        "text-color-rhapsody-in-blue-2",
                                      class: `${
                                        _vm.$screen.width <= 576
                                          ? "card-inside"
                                          : "scheduler-border"
                                      }`,
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(653, "Entry") +
                                              " " +
                                              (index + 1)
                                          ) +
                                          "\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                { staticClass: "pt-3 pb-3" },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  654,
                                                  "Source"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                staticClass:
                                                  "bg-color-grams-hair",
                                                attrs: {
                                                  value: _vm.entityLabel,
                                                  disabled: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(810, "Date"),
                                              },
                                            },
                                            [
                                              _c("v-date-picker", {
                                                attrs: {
                                                  value: item.strDate,
                                                  locale: _vm.lang,
                                                  masks: {
                                                    input: "DD/MM/YYYY",
                                                  },
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    return _vm.handleInputCarbonDateTime(
                                                      $event,
                                                      index
                                                    )
                                                  },
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        inputValue,
                                                        togglePopover,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  staticClass:
                                                                    "bg-white",
                                                                  attrs: {
                                                                    value:
                                                                      inputValue,
                                                                    readonly:
                                                                      "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      togglePopover,
                                                                  },
                                                                }
                                                              ),
                                                              _c(
                                                                "b-input-group-append",
                                                                [
                                                                  _c(
                                                                    "b-input-group-text",
                                                                    {
                                                                      staticClass:
                                                                        "cursor-pointer",
                                                                      on: {
                                                                        click:
                                                                          togglePopover,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          "Calendar"
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                "#06263E",
                                                                              size: 16,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("co-two-clap", {
                                    key: `coTwoClapLocation_${index}`,
                                    attrs: {
                                      "parent-id": +item.entityId,
                                      "entity-type": 1,
                                      "for-create": false,
                                      "external-carbon-data": item,
                                      "hide-btn-reviewed": "",
                                      "disable-carbon-type-selector": "",
                                      "hide-co-two-category-selector": "",
                                    },
                                    on: {
                                      "co-two-clap:change": function ($event) {
                                        return _vm.handleCoTwoClapChange(
                                          $event,
                                          index
                                        )
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-center" },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn-transparent text-color-burning-tomato",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleClickRemoveEntry(index)
                                  },
                                },
                              },
                              [
                                _c(_vm.getLucideIcon("MinusCircle"), {
                                  tag: "component",
                                  attrs: { size: 20 },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                }),
                0
              ),
            ]
          ),
          _c(
            "b-row",
            [
              _c("b-col", [
                _c(
                  "div",
                  {
                    staticClass: "d-flex mt-2",
                    class: {
                      "justify-content-end": _vm.carbonToDelete.length === 0,
                      "justify-content-between": _vm.carbonToDelete.length > 0,
                    },
                  },
                  [
                    _vm.carbonToDelete.length > 0
                      ? _c("div", { staticClass: "d-flex" }, [
                          _c("div", [
                            _c(
                              "button",
                              {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.righttop",
                                    value: _vm.FormMSG(
                                      427,
                                      "The number displayed here is the number of entries you have saved and wish to delete."
                                    ),
                                    expression:
                                      "\n\t\t\t\t\t\t\t\t\tFormMSG(427, 'The number displayed here is the number of entries you have saved and wish to delete.')\n\t\t\t\t\t\t\t\t",
                                    modifiers: { hover: true, righttop: true },
                                  },
                                ],
                                staticClass:
                                  "btn-transparent text-color-blue-streak",
                              },
                              [
                                _c(_vm.getLucideIcon("Info"), {
                                  tag: "component",
                                  attrs: { size: 16 },
                                }),
                              ],
                              1
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass:
                                "fs-14 fw-700 text-color-rhapsody-in-blue",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.FormMSG(485, "Entry to delete :")
                                  ) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            { staticClass: "fs-14 fw-700 text-color-negative" },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.carbonToDelete.length) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn-transparent text-color-rhapsody-in-blue",
                        attrs: { type: "button" },
                        on: { click: _vm.handleClickAddEntry },
                      },
                      [
                        _c("div", { staticClass: "d-flex" }, [
                          _c(
                            "div",
                            { staticClass: "mr-2" },
                            [
                              _c(_vm.getLucideIcon("PlusCircle"), {
                                tag: "component",
                                attrs: { size: 20, color: "#47C7BF" },
                              }),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "fs-14 fw-700" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(45, "Add entry")) +
                                "\n\t\t\t\t\t\t\t"
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
	<div>
		<p class="mt-2 mb-2 fs-16 fw-700">{{ FormMSG(854, 'Main') }}</p>

		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(855, 'Electricity')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionElectricityTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(1259, 'kWh') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(856, 'Gas')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionGasTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(1260, 'm3') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(857, 'Water')">
					<b-input-group>
						<b-form-input :value="currentLocation.consumptionWaterTotal" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ FormMSG(1260, 'm3') }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<p class="mt-2 mb-2 fs-16 fw-700">{{ FormMSG(890, 'Generator') }}</p>

		<b-row>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(742, 'Fuel type')">
					<b-form-select v-model="generatorFuelType" :options="fuelTypeOptions" />
				</b-form-group>
			</b-col>
			<b-col sm="12" md="4" lg="4" xl="4">
				<b-form-group :label="FormMSG(701, 'Volume used')">
					<b-input-group>
						<b-form-input :value="volumeUsed" class="bg-color-grams-hair" disabled />
						<b-input-group-append>
							<b-input-group-text class="bg-color-north-wind text-color-white">
								{{ generatorUnit }}
							</b-input-group-text>
						</b-input-group-append>
					</b-input-group>
				</b-form-group>
			</b-col>
		</b-row>

		<b-row class="mt-2">
			<b-col>
				<div class="d-flex justify-content-center">
					<b-button variant="outline-primary" @click="handleClickeAddEditConsumption">
						{{ FormMSG(705, 'Add/Edit consumption') }}
					</b-button>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ViewConsumptionEnergy',

	mixins: [languageMessages, globalMixin],

	props: {
		location: { type: Object, required: true }
	},

	data() {
		return {
			currentLocation: {},
			generatorFuelType: 0
		};
	},

	watch: {
		location: {
			handler(newVal) {
				this.currentLocation = newVal;
			},
			immediate: true,
			deep: true
		}
	},

	computed: {
		fuelTypeOptions() {
			return this.FormMenu(1402);
		},
		volumeUsed() {
			if (this.generatorFuelType === 0) {
				return this.currentLocation.volumeTotalDieselUsed;
			} else if (this.generatorFuelType === 1) {
				return this.currentLocation.volumeTotalGasUsed;
			} else if (this.generatorFuelType === 2) {
				return this.currentLocation.volumeTotalGasolineUlUsed;
			} else if (this.generatorFuelType === 3) {
				return this.currentLocation.volumeTotalHydrogenUsed;
			}
		},
		generatorUnit() {
			if (this.generatorFuelType === 0 || this.generatorFuelType === 2) {
				return this.FormMSG(1265, 'L');
			} else if (this.generatorFuelType === 1) {
				return this.FormMSG(1621, 'm3');
			} else if (this.generatorFuelType === 3) {
				return this.FormMSG(1622, 'kg');
			}
		}
	},

	methods: {
		handleClickeAddEditConsumption() {
			this.$emit('view-consumption-energy:add-edit');
		}
	}
};
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("p", { staticClass: "mt-2 mb-2 fs-16 fw-700" }, [
        _vm._v(_vm._s(_vm.FormMSG(854, "Main"))),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(855, "Electricity") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value:
                            _vm.currentLocation.consumptionElectricityTotal,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1259, "kWh")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(856, "Gas") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.currentLocation.consumptionGasTotal,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1260, "m3")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(857, "Water") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: {
                          value: _vm.currentLocation.consumptionWaterTotal,
                          disabled: "",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(1260, "m3")) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("p", { staticClass: "mt-2 mb-2 fs-16 fw-700" }, [
        _vm._v(_vm._s(_vm.FormMSG(890, "Generator"))),
      ]),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(742, "Fuel type") } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.fuelTypeOptions },
                    model: {
                      value: _vm.generatorFuelType,
                      callback: function ($$v) {
                        _vm.generatorFuelType = $$v
                      },
                      expression: "generatorFuelType",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { sm: "12", md: "4", lg: "4", xl: "4" } },
            [
              _c(
                "b-form-group",
                { attrs: { label: _vm.FormMSG(701, "Volume used") } },
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        staticClass: "bg-color-grams-hair",
                        attrs: { value: _vm.volumeUsed, disabled: "" },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            {
                              staticClass:
                                "bg-color-north-wind text-color-white",
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.generatorUnit) +
                                  "\n\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-2" },
        [
          _c("b-col", [
            _c(
              "div",
              { staticClass: "d-flex justify-content-center" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { variant: "outline-primary" },
                    on: { click: _vm.handleClickeAddEditConsumption },
                  },
                  [
                    _vm._v(
                      "\n\t\t\t\t\t" +
                        _vm._s(_vm.FormMSG(705, "Add/Edit consumption")) +
                        "\n\t\t\t\t"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }